import React from "react"
import Header from "./header"
import Footer from "./footer"

export default function Layout({ children }) {
  return (
    <div className="container-fluid">
      <Header siteTitle="Insurance Plans Blog" />
      <div>{children}</div>
      <Footer />
    </div>
  )
}
