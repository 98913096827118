import React, { useState } from "react"
import styles from "./header.module.scss"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

const Header = () => {
  const openCloseMenu = () => {
    updateMenu(() => !menu)
  }
  const [menu, updateMenu] = useState(false)
  const images = useStaticQuery(graphql`
      query {
          logo: file(relativePath: { eq: "logo1.png" }) {
              childImageSharp {
                  fixed(height: 32, width: 257) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)
  return (
    <header className={styles.header}>
      <div className="container">
        <nav className={styles.nav}>
          <div className={styles.logo}>
            <a href="https://www.insuranceplans.org">
              <Img fixed={images.logo.childImageSharp.fixed} />
            </a>
          </div>
          <div className={styles.menu + " is-hidden-touch"}>
            <div className={styles.menu_item}>
              <a href="/">Blog</a>
            </div>
            <div
              className={styles.menu_item}
              onClick={openCloseMenu}
              onKeyDown={openCloseMenu}
              role="link"
              tabIndex="0"
            >
              Our Products
              <FontAwesomeIcon icon={faAngleDown} className={styles.icon} />
              <div
                className={
                  menu ? styles.dropDown : styles.dropDown + " hideElement"
                }
              >
                <div className={styles.item}>
                  <a href="https://www.insuranceplans.org/medicare.html">
                    Medicare Insurance
                  </a>
                </div>
                <div className={styles.item}>
                  <a href="https://www.insuranceplans.org/health.html">
                    Health Insurance
                  </a>
                </div>
                <div className={styles.item}>
                  <a href="https://www.insuranceplans.org/life.html">
                    Life Insurance
                  </a>
                </div>
                <div className={styles.item}>
                  <a href="https://www.insuranceplans.org/auto.html">
                    Auto Insurance
                  </a>
                </div>
                <div className={styles.item}>
                  <a href="https://www.insuranceplans.org/home.html">
                    Home Insurance
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contact}>
            <div className={styles.get_started}>Get Started</div>
            <div className={styles.phone}>
              <div className={styles.talk_with + " is-hidden-touch"}>Talk with an agent</div>
              <div className={styles.phone_number}>
                <a href="+18552683799">855-COVER-99</a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
